import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import "katex/dist/katex.min.css";

import Layout from "../components/layout";
import NextPrevButtons from "../components/NextPrevButtons";

export const LessonTemplate = ({
  title,
  description,
  banner,
  next,
  previous,
  content,
}) => {
  return (
    <>
      <header className={`banner banner-${banner}`}>
        <div className="content">
          <h2 style={{ color: "#fff" }}>{title}</h2>
          <p className="description">{description}</p>
        </div>
      </header>
      <NextPrevButtons next={next} prev={previous} />

      <MDXRenderer>{content}</MDXRenderer>
      <NextPrevButtons next={next} prev={previous} />
    </>
  );
};

const Post = ({ data, pageContext }) => {
  const { title } = pageContext;
  const getLessonInfo = (data, title) => {
    const {
      node: {
        childMdx: { frontmatter, body },
      },
      next,
      previous,
    } = data.allFile.edges.filter(
      ({ node }) => node.childMdx.frontmatter.title === title
    )[0];
    return {
      next: next && {
        title: next.childMdx.frontmatter.title,
        slug: next.childMdx.fields.slug,
      },
      previous: previous && {
        title: previous.childMdx.frontmatter.title,
        slug: previous.childMdx.fields.slug,
      },
      post: {
        banner: frontmatter.banner,
        thumbnail: frontmatter.thumbnail.childImageSharp.fluid,
        body,
        description: frontmatter.description,
      },
    };
  };
  const { post, next, previous } = getLessonInfo(data, title);
  const { banner, thumbnail, body, description } = post;

  return (
    <Layout
      title={title}
      description={description}
      classTitle="main"
      classSection="lesson"
      image={thumbnail}
    >
      <LessonTemplate
        title={title}
        banner={banner}
        description={description}
        next={next}
        previous={previous}
        content={body}
      />
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "posts" } }
      sort: { fields: childMdx___frontmatter___date }
    ) {
      edges {
        node {
          id
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
              description
              banner
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 75, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            body
            excerpt
          }
        }
        next {
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
        previous {
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  }
`;
